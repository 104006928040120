<template>
  <el-form ref="BuyForm" :model="formline" id="commonform" size="mini" :rules="rules" :hide-required-asterisk="true">
    <!-- 股票交易 -->
    <div>
      <el-form-item label="买入价格" prop="price">
        <el-input onkeyup="if(isNaN(value))execCommand('undo')" v-model="formline.price" style="width:100%"></el-input>
      </el-form-item>
      <el-form-item label="买入数量" prop="num">
        <el-input v-model="formline.num" @blur="blurNumber" style="width:100%"></el-input>
        <!-- <span class="unit">股</span> -->
      </el-form-item>
      <el-form-item label="报价方式" prop="name">
        <el-select v-model="formline.name" placeholder="请选择报价方式" style="width:100%">
          <el-option :value="'定价'" label="定价"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="可用资金">
        <!-- <el-input v-model="formline.useMoney" disabled  style="width:100%"></el-input> -->
        <div style="float:right">{{ formline.useMoney }}</div>
        <span class="unit"></span>
      </el-form-item>
    </div>
    <!--    <div class="maxbuy">-->
    <!--      <el-form-item label="最大可买:" prop="name">-->
    <!--        <el-input v-model="formline.num"  @blur="blurNumber" style="margin-right:10px;"></el-input>-->
    <!--        <el-button icon="el-icon-plus" type="primary" class="handlebtn" @click="formline.radio=1">全部</el-button>-->
    <!--        <el-radio-group v-model="formline.radio" >-->
    <!--          <el-radio :label="0.5">1/2</el-radio>-->
    <!--          <el-radio :label="0.33">1/3</el-radio>-->
    <!--          <el-radio :label="0.25">1/4</el-radio>-->
    <!--          <el-radio :label="0.2">1/5</el-radio>-->
    <!--        </el-radio-group>-->
    <!--      </el-form-item>-->
    <!--    </div>-->
    <el-form-item>
      <el-button style="width: 100%;font-size: 14px" type="primary" class="handlebtn" @click="isSalebuy" :disabled="buyDisabled.buyDisabled">模拟买入</el-button>
      
      <div style="width: 100%;text-align: center; color: #75728C;">
        <!-- <span class="reset" style="width: 100%;" @click="resetForm">重填</span> -->
        <el-button class="reset" style="width: 100%;margin-top: 10px;font-size: 14px" @click="resetForm">重填</el-button>
      </div>
    </el-form-item>
  </el-form>
</template>

<script>
import { onMounted, reactive, ref, toRefs, watch } from "vue";
import { findFunds, placeAnOrder } from "../../../course-manage/trade-manage/components/TradeDetails/detailsApi";
import { ElMessage } from "element-plus";
import { codeDeal, dealCodeSearchNum } from "../commonMethods.js";
export default {
  name: "BuyForm",
  props: {
    layout: {
      type: String,
      default: 'flex'
    },
    code: {
      type: String
    },
    price: {
      type: String
    }
  },
  setup(props, ctx) {
    const BuyForm = ref()
    const buyDisabled = reactive({ buyDisabled: false });
    const formline = reactive({
      name: "",
      price: 0,
      radio: 1,
      useMoney: '',
      num: '',
    });
    const rules = reactive({
      name: [{ required: true, message: '请选择报价方式', trigger: 'trigger' },],
      price: [{ required: true, message: '请输入买入价格', trigger:["blur","change"] }],
      num: [{ required: true, message: '请输入买入数量', trigger: 'blur' }],

    })
    onMounted(() => {
      geyMoney()
    })

    watch(() => [props.price], ([newPrice]) => {
      formline.price = parseFloat(newPrice)
    })

    let geyMoney = () => {
      findFunds({
        acid: JSON.parse(sessionStorage.getItem('acid')),
        // acid: JSON.parse(sessionStorage.getItem('classInfo')).acid,
        exchange: 0
      }).then(res => {
        if (res.data.code === '200') {
          formline.useMoney = res.data.data.funds[0].freeAmount
        }
      })
    }

    const resetForm = () => {
      BuyForm.value.resetFields()
    }

    let blurNumber = (val) => {
      let num = parseInt(val.target.value)
      let minNum = dealCodeSearchNum(props.code.slice(2), '1')
      formline.num = num - num % minNum
      if (isNaN(formline.num)) formline.num = 0
    }

    const isSalebuy = async () => {
      BuyForm.value.validate((valid) => {
        if (valid) {
          let totalPrice = formline.price * formline.num
          if (totalPrice > formline.useMoney) {
            ElMessage.warning('买入总价不能大于可用资金')
            return 
          }
          /*
         * tposition 开仓  0 ，平仓方向 1
         * direction 买 0 卖 1 方向
         * 股票,债券 买  tp 0 dir 0
         * 股，债 卖  tp 1 dir 1
         * 期货 买开 tp  0 dir 0
         *    买平  tp 1  dir 0
         *    卖开   tp 0 dir 1
          *    卖平 tp 1 dir 1
         * */
          buyDisabled.buyDisabled = true;
          let parame = {
            accountId: JSON.parse(sessionStorage.getItem('acid')),
            tposition: 0,
            insCode: props.code.length>6?codeDeal(props.code.slice(2), '1'):codeDeal(props.code, '1'),
            direction: 0,
            orderPrice: parseFloat(formline.price),
            orderVol: parseFloat(formline.num),
            code:props.code.slice(2)
          }
          placeAnOrder(parame).then(res => {
            if (res.data.code === '200') {
              buyDisabled.buyDisabled = false;
              ElMessage.success(res.data.msg)
              resetForm()
              setTimeout(() => {
                geyMoney()
              }, 4000)
            } else {
              buyDisabled.buyDisabled = false;
              ElMessage.error(res.data.msg)
            }
          })
        }
      })
    }


    return {
      buyDisabled,
      formline,
      BuyForm,
      rules,
      resetForm,
      isSalebuy,
      blurNumber
    };
  },
};
</script>

<style lang="less" scoped>
#commonform {

    .el-form-item {
      width: 100%;
      display: flex;
      padding-bottom: 23px;
      // &:deep(.el-form-item__label) {
      //   width: 90px;
      // }
      // &:deep(.el-form-item__content) {
      //   flex: 1;
      //   position: relative;
      //   padding-right: 20px;
      //   .unit {
      //     margin-left: 20px;
      //     //position: absolute;
      //     //right: 0;
      //     //top: 50%;
      //     //transform: translate(10%, -50%);
      //   }
      // }
  }
  .maxbuy {
    display: flex;
    width: 100%;
    .el-form-item {
      display: flex;
      width: 100%;
      // &:deep(.el-form-item__label) {
      //   width: 90px;
      // }
      &:deep(.el-form-item__content) {
        display: flex;
        .el-radio-group {
          display: flex;
          align-items: center;
          margin-left: 50px;
          .el-radio__input.is-checked .el-radio__inner {
            color: #feae10;
            background: #feae10;
            border-color: #feae10;
          }
          .el-radio__input.is-checked + .el-radio__label {
            color: #feae10;
          }
        }
      }
    }
  }
  .handlebtn {
    background: #feae10;
    border-color: #feae10;
  }
  .reset {
    // color: #feae10;
    background:#F4F4FA 
    // border-color: #feae10;
  }
  .reset {
    font-size: 14px;
    cursor:pointer;
  }
  .reset:hover{
    color: #9EB7FE;
  }
}
</style>
