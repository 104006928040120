import dayjs from 'dayjs'
// 校验最小下单数量
export const dealCodeSearchNum = (code, type) => {
  let num = 1;
  let name =
    type === "0"
      ? "all"
      : type === "1"
      ? "shares"
      : type === "2"
      ? "bond"
      : "forward";
  let codeList = JSON.parse(sessionStorage.getItem(name));
  for (let j in codeList) {
    if (code === codeList[j].fgsid) {
      num = codeList[j].vt;
    }
  }
  return num;
};

// 下单时 外码转内码
export const codeDeal = (code, type) => {
  let codeName = "";
  let name =
    type === "0"
      ? "all"
      : type === "1"
      ? "shares"
      : type === "2"
      ? "bond"
      : "forward";
  let codeList = JSON.parse(localStorage.getItem(name));
  //期货代码下单需传'_'之后的内容
  if (type == "3") {
    code = code.split("_")[1];
  }
  for (let j in codeList) {
    if (code === codeList[j].fgsid) {
      codeName = codeList[j].fid;
    }
  }
  return codeName;
};
// 下单时 查询交易所
export const codeDealJys = (code, type) => {
  let codeName = "";
  let name =
    type === "0"
      ? "all"
      : type === "1"
      ? "shares"
      : type === "2"
      ? "bond"
      : "forward";
  let codeList = JSON.parse(localStorage.getItem(name));
  for (let j in codeList) {
    if (code === codeList[j].fgsid) {
      codeName = codeList[j].fjys;
    }
  }
  return codeName;
};

// 获取code name
export const codeDealName = (oldcode, type) => {
  let code= oldcode
  if(oldcode.length>6){
    code= oldcode.substring(2)
  }
  let codeName = "";
  let name =
    type === "0"
      ? "all"
      : type === "1"
      ? "shares"
      : type === "2"
      ? "bond"
      : "forward";
  let codeList = JSON.parse(localStorage.getItem(name));
  for (let j in codeList) {
    if (code === codeList[j].fgsid) {
      codeName = codeList[j].fname;
      
    }
  }
  return codeName;
};

// 遍历外汇币种处理方法
export const ergodic = (data)=>{
  data.forexOrderVOList.forEach((item) => {
    item.createTime = dayjs(item.createTime).format("YYYY-MM-DD HH:mm:ss")
    data.currencyData.forEach((every) => {
      if (item.buyerCurrency == every.abbr) {
        item.buyerCurrency = every.name
      }
      if (item.sellerCurrency == every.abbr) {
        item.sellerCurrency = every.name
      }
      if (item.tradingDirection == every.abbr) {
        item.tradingDirection = every.name
      }
      // 资金流水页面剩余金额 
      item.sellerBalanceed =  item.sellerCurrency  +  ':' + item.sellerBalance 
      // 成交详情
      item.sellerCurrencys = item.sellerCurrency + '/' + item.buyerCurrency
      item.capitalBalance = item.buyerCurrency + ':' + item.buyerBalance + '/' + item.sellerCurrency + ':' + item.sellerBalance
    })
  });
  data.loading = false
}

//千分位公用方法
export const thousandthConversion = (num)=>{
	if(num) {
		return parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
	} else {
		return '0.00'
  }
}